<template>
  <section class="faq">
    
      
        <!--  ({{ rldata['objcode'] }}/{{ rldata['loccode'] }}) -->  
        
  <!--    
    <div v-if="rldata['is_loaded_data']" class="page-header" >
    
      <h3 class="page-title">
        {{  rldata['r_objects']['objname'] }}
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">Заявка № {{ rldata['r_tickets']['ticknum'] }}</li>
          <li class="breadcrumb-item active" aria-current="page"><router-link class="" v-bind:to="'/?t='+$root.add_v()"  >Отменить</router-link></li>
        </ol>
      </nav>
    </div>
      -->
      
  
         
    <div class="row">
        


        
        
        
        
      
        
        
        
        
        

            <!-- COL1 -->   
             <div class="col-md-12">
                 
                 
                 
                 
               <div class="row" v-show='rldata.is_fin_form'>   
                     
                 <div class="col-12 grid-margin mb-2">
                   <div class="card">
                     <div class="faq-block card-body">
                            <h4 class="mt-2">Заявка оформлена!</h4>
                            <p class="mt-2">Заявка № {{ rldata['r_tickets']['ticknum'] }} передана в работу. Спасибо за обращение!</p>
                     </div> 
                   </div> 
                 </div> 
                </div> 
                 
                 <div class="row" v-show='rldata.is_corparea_form'>   
                  <div class="col-12 grid-margin mb-2">
                   <div class="card">
                     <div class="faq-block card-body">
                         
                     <h4 class="mt-2">Введите пароль</h4>
                       <b-input     v-model='rldata.corp'  placeholder="Пароль"  > </b-input>
                     
                       <div>
                     <a class=" mr-2 text-small" href="#"  v-on:click="show_corparea_form" >Войти</a>
                     </div>  
                     
                     <div class=" mt-4">
                      <a class=" mt-4 mr-2 text-small" href="#"  v-on:click="ticket_form" >Вернуться Назад</a>
                     </div> 
                     
                        </div> 
                   </div> 
                 </div> 
                </div> 
                 
                 
                 
                 
               <div class="row" v-show='rldata.is_ticket_form'>



                 <div class="col-12 grid-margin mb-2">
                   <div class="card">
                     <div class="faq-block card-body">


                         <p class="mt-1 mb-2 ml-0 ">Уточните локацию</p>  <!--  -->
                         <b-input     v-model='rldata.client_location'  placeholder=""  > </b-input>
                      
                         <p class="mt-4 mb-2 ml-0 ">Оцените текущий уровень уборки в локации</p>   
                         
                      <div class="ml-2 mt-1 mb-2">
                        <star-rating v-bind:increment="1" v-model="rldata.client_raiting" v-bind:max-rating="5" v-bind:star-size="40" v-bind:show-rating="false"></star-rating> 
                      </div>
                        
                        
                          
                         <h5 class="mt-4 mb-3 ml-0 ">Уточнение заявки</h5>
                         
                          <p class="mt-4 mb-2 ml-0 ">Заявка кратко</p>   
                         
                          <b-input    v-model='rldata.client_problem'  placeholder="Разводы на полу"  > </b-input>
                          
                          <p class="mt-4 mb-2 ml-0 ">Ваш телефон смс для отчета о выполнении</p>   
                         
                
                          <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">+7</span>
                                        </div>
                                        <input id="client_tel"  type="text" v-mask="'(999) 999 99 99'" size="10" v-model="rldata.client_tel" class="form-control " placeholder="Телефон"/>  <!-- v-bind:style="this.error_tel_style" -->
                                    </div>
                          
                          
                          
                           <p class="mt-4 mb-2 ml-0 ">Ваше имя</p>   
                         
                          <b-input  v-model="rldata.client_name"    placeholder="Имя"  > </b-input>
                          
                            <label class="ml-4 mt-2" style="font-size: 14px;">  
                                 <input type="checkbox" class="form-check-input" v-model='rldata.client_is_requare_call'  style="opacity: 1;"   >Свяжитесь со мной для уточнения заявки
                            </label><!-- checked="checked" -->
                 
                            
                            
                            <div>
                            <p class="mt-3 mb-2 ml-0 ">Приложите фотографию, если надо</p> 
                             <b-form-file   @change="getFile" accept="image/png,image/gif,image/jpeg" browse-text='Приложить фото' id="inpu8"  placeholder=""></b-form-file>
                            </div>
                            
                             <!--
                            <div v-if="rldata.img_data===''">
                                <p class="mt-3 mb-2 ml-0 ">Приложите фотографию, если надо</p> 
                                <a href="#" class="mt-2 mb-2 ml-3 "  style="font-size: 14px;" v-on:click="camera_form">Приложить</a> 
                            </div> 
                            <div v-else>
                                <p class="mt-3 mb-2 ml-0 ">Приложено фото</p>    
                                <a href="#" class="mt-2 mb-2 ml-3 "  style="font-size: 14px;" v-on:click="camera_form">Изменить</a> 
                            </div> 
                           -->
                             
                            
                        <!--  <div class="d-flex flex-column mt-4">  -->
                        <div >
                                 <label class="ml-4 mt-4" style="font-size: 14px;">  
                                    <input type="checkbox" v-model='rldata.oferta' class="form-check-input"  style="opacity: 1;"   >Даю согласие на обработку персональных данных
                               </label>
                        </div> 
                        
                         <div >
                                <b-button  class="btn-danger  ml-0 mb-2 mt-1" v-on:click="update_ticket" v-bind:disabled="!rldata.oferta || rldata.flags_logic.is_act_disabled">Отправить</b-button>
                           </div>    
                      <!--   </div>  -->
                         
                         


                          <p v-if="rldata.flagserror_common.is_error_in_sending_data" class="text-danger">{{ this.$root.error_text.text_error_in_sending_data }}</p>
                          <p v-if="rldata.flagserror_common.is_error_execution_api" class="text-danger" >{{ this.$root.error_text.text_error_execution_api }}</p>


                     </div>
                   </div>
                 </div>























               </div>
             
                 
                 
                 
                 
  <div class="row" v-show='rldata.is_camera_form'>         
                 
           <div class="col-12 grid-margin mb-2">
                   <div class="card">
                     <div class="faq-block card-body">
      
       
                
                
              
                
                <div v-show="rldata.is_snap_photo" >
                 
                    <div class="">
                    
                    <!-- Рабочий код - выбор камеры
                    <p>Выберите камеру:</p>
                <button id="switchFrontBtn"  class="btn btn-link  ml-0 mb-2 mt-1"  v-on:click="switchFrontBtn_click">Передняя камера</button>
                <button id="switchBackBtn"  class="btn btn-link  ml-0 mb-2 mt-1"  v-on:click="switchBackBtn_click">Задняя камера</button> 
              -->
                   
                     
                    <h4 class="ml-0 mb-3">Камера</h4>
                    
                    
                    
                    <div v-show="rldata.is_show_takephoto_button" class="d-flex flex-row justify-content-center">
                        <div class="" style="width:320px;">
                            <video id="cam" autoplay muted playsinline style="width:320px;">Not available!!!</video>                
                            <canvas   id="canvas" style="display:none; width:320px;"></canvas>  
                        </div>
                    </div>
              
                    <div v-show="!rldata.is_show_takephoto_button">    
                        
                        <div v-if="!rldata.is_faild_get_cam">
                            <p class="ml-4">  Активация камеры...</p>
                        </div>
                        <div v-else>
                            <p class="ml-4">Не удалось активировать камеру</p>
                          
                            <div v-if="rldata.is_requested_device_not_found">
                                <p class="ml-4">Камера с обратной стороны устройства недоступна, приложить фото к заявке не получится.</p>
                            </div>
                            <div v-if="rldata.is_premission_denied">
                               <p class="ml-4">Разрешение на предоставление доступа к камере отклонено. В "Настройках Браузера" в разделе "Настроек сайта", необходимо дать "Разрешение" для "Камеры". Повторные запросы на предоставление разрешения к камере со стороны "сайта" запрещены политикой браузера.</p>
                            </div> 
                            <button id="switchBackBtn"  class="btn btn-danger  ml-5 mb-3 mt-1"  v-on:click="switchBackBtn_click">Попробовать еще раз</button> 
                            
                        </div>
                        
                    </div>
                    
                    
                    <div v-show="rldata.is_show_takephoto_button" class="d-flex flex-row justify-content-center">
                                <div v-if="rldata.is_show_takephoto_button" class=" mt-2">
                                  <a id="snapBtn"  href="#" class=" text-small  "  v-on:click="snapBtn_click">Сделать фото</a>
                                </div>
                    </div>
                    
                    <div class="d-flex flex-row mt-2">
                                <div class="ml-4">
                                    <a id="snapBtn" href="#"  class=" text-small "  v-on:click="ticket_form">Назад</a>
                                </div>
                                
                    </div>
                   
                    
                    
                  
                </div>
              </div><!-- comment -->
                
                <div v-show="rldata.is_result_photo">
                    
                    <h4 class="ml-0 mb-2">Фотография готова</h4>
                    
                    <div class="mb-3">
                        <a href="#" class="text-small "  v-on:click="remakephoto_click">Переделать фото</a> 
                    </div>
                    
                    <div class="d-flex flex-row justify-content-center">
                        <div class="" style="width:320px;">
                            <img id="photo" style="width: 320px;">  
                        </div>
                    </div>
                
                    <div class="d-flex flex-row mt-2">
                                <div v-if="rldata.is_show_takephoto_button" class="ml-4">
                                    <a href="#" class=" text-small  "  v-on:click="ticket_form">Готово</a> 
                                </div> 
                    </div>
                    
                </div>
             
  
                         
                         
                         
                         
  
           </div>  
         </div>  
        </div>  
  
  </div>               
                 

        
                 
                 
                 
                 
                 
</div>



              <!-- COL1 -->  






       
       
       
      </div>
        
     
        
        
        
        
        
        
        
        
        
        
        
        
    <div v-if="rldata['is_loaded_data'] && !rldata.is_fin_form && !rldata.is_corparea_form" class="page-header mt-0" >
        <!--  ({{ rldata['objcode'] }}/{{ rldata['loccode'] }}) -->
        
     <!--   
        <div class="mt-2 ml-2 d-flex flex-row">
            <img class='' style='max-width:130px; height:20px;' src="/resource/logo/logo_blue_right.png"  /> 
        </div>
   -->
   
   <div class="d-flex flex-row-reverse"></div>
   
      <nav aria-label="breadcrumb mt-2">
        <ol class="">
         <!-- <router-link class="text-gray text-small mr-2" v-bind:to="'/?t='+$root.add_v()"  >Раздел для персоонала</router-link> -->
              <a class="text-gray text-small mr-2" href="#" v-on:click="show_corparea_form" >Раздел для персоонала</a>
        </ol>
      </nav>
   
   
   
    </div>
        
        
        
        
       
  
  
  
  
  </section>
</template>

<script>
import $ from 'jquery'
import axios from 'axios';
import axiosRetry from 'axios-retry';   


var data = {
    objcode : '',
    loccode : '', 
    client_problem : '',
    client_location : '',
    client_tel : '',
    client_name : '',
    client_is_requare_call : false,
    client_raiting : 5,
    
    oferta : true,
    
    source : '',
    r_qrcodes : [],
    r_objects : [],
    r_tickets : [],
    
    img_data : "", 
    
    is_camera_form : false,
    is_ticket_form : true,
    is_fin_form : false,
    is_corparea_form : false,
    
    
    is_result_photo : true,
    is_snap_photo : false,
    is_loaded_data : false,
    is_faild_get_cam : true,
    is_requested_device_not_found : false,
    is_premission_denied : false, 
    
    
    is_show_takephoto_button : false,
    flags_logic: {
        is_act_disabled : false,
    },    
    flagserror_common: {
            is_error_in_sending_data: false,
            is_error_execution_api: false,
    },    
    flagserror_action: {
            //is_error_email_exist: false,
            //text_error_email_exist: "Пользователь с таким email уже зарегистрирован, пожалуйста авторизуйтесь. Если вы забыли свой пароль, проидет процедуру восстановления доступа.  ",
    },
    
    camera_type : null,
   mediaStream : null,
   timer_check_is_loaded_data : 1, 
   
   corp : "",
   
   constraints : { 
    audio: false, 
    video: { 
      width: {ideal: 320}, 
      height: {ideal: 240},
      facingMode: "environment"
    }, 
  },
  
  
}      
  
import StarRating from 'vue-star-rating';
  
export default {
  name: 'addqr',  
components: {
  StarRating
},

  
    data () { //тюненгуемые переменные и функции
        return {
            rldata: data,  
        };
    },
  
  
  mounted () {
   
   // this.$root.check_version(this.rldata, true);
   
       
         //получить данные о пользователе из ls
    var url = new URL(window.location.href);    
    let qurl=url.searchParams.get("url");  
    if (qurl===null)
    {
        console.log("qurl==null");
        this.rldata['objcode']=url.searchParams.get("o");             
        this.rldata['loccode']=url.searchParams.get("l");
        this.rldata['source']="qr";
        
    }
    else
    {
        console.log("qurl="+qurl); 
         
        let arr_o=qurl.split('.', 1);
        this.rldata['objcode']=arr_o[0].toUpperCase();
        let arr_l=qurl.split('/');
        this.rldata['loccode']=arr_l[arr_l.length-1].toUpperCase();
        this.rldata['source']="web";
        console.log(this.rldata['objcode']+"|"+this.rldata['loccode']);
        if (this.rldata['objcode']==='ASK')
        {
           this.$router.push('/ask?ticknum='+this.rldata['loccode']);
        }
        
    }
    
    
    
    this.$root.get_data_by_objcode_and_loccode(this.rldata); //a_qrcodes
    
   
    
    this.rldata['client_raiting']=0;
    this.rldata['client_problem']='';
    this.rldata['client_location']='';
    this.rldata['client_tel']='';
    this.rldata['client_name']='';
    this.rldata['client_is_requare_call']=false;
    this.rldata['oferta']=true;
    this.rldata['flags_logic']['is_act_disabled']=false;
    
    this.rldata['is_camera_form']=false;
    this.rldata['is_ticket_form']=true;
    this.rldata['is_fin_form']=false;
    this.rldata['is_corparea_form']=false;
    
    this.rldata['is_result_photo']=true;
    this.rldata['is_snap_photo']=true;
    this.rldata['is_show_takephoto_button']=false;
    this.rldata['camera_type']=null;
    
    this.rldata['img_data'] = ""; 
    
      
   this.rldata['is_faild_get_cam']=true;  
   this.rldata['is_requested_device_not_found']=false;
   this.rldata['is_premission_denied']=false; 
   //функция, подтягивающия данные по objcode и loccode и создающая заявку................... с указанным IP
   //
          
   
   this.rldata['timer_check_is_loaded_data']=1;
   
   
   
   
   this.rldata['corp']= localStorage.getItem('corp');
   
  
   
   
   
   
   
  
  },  
  
  
  
  
  
  
methods: {
     
     
      getBase64 : function(file) {
   var reader = new FileReader();
   reader.readAsDataURL(file);
   reader.onload = function () {
     console.log(reader.result);
   };
   reader.onerror = function (error) {
     console.log('Error: ', error);
   };
},

      getFile : function (event)   //(event)  
       {
            
            console.log(event.target.files[0]);
            var file=event.target.files[0];
            
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event)  => {
              console.log(reader.result);
              this.rldata['img_data']=reader.result;
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
              this.rldata['img_data']="";
            };
          }, 
     
     
     
     
     
      camera_form : function ()   //(event) 
    {   
         this.rldata['is_camera_form']=true;
         this.rldata['is_ticket_form']=false;
         this.rldata['is_result_photo']=false;
         this.rldata['is_snap_photo']=true;
         
         if (this.rldata['camera_type']===null)
         {
             this.switchBackBtn_click();
         }
         
         //this.clearPhoto(); валиться
    },
      
      
      
    show_corparea_form : function ()   //(event) 
    {    
         this.rldata['is_camera_form']=false;
         this.rldata['is_ticket_form']=false;
         this.rldata['is_fin_form']=false;
         this.rldata['is_corparea_form']=true;
         
         if (this.rldata['corp']==='1122')
         {
             //TODO if in local storage pass - > redirect
             localStorage.setItem('corp', '1122');
             console.log("TEDIRECT ="+this.rldata['r_tickets']['ticknum']);
             this.$router.push('/corp?ticknum='+this.rldata['r_tickets']['ticknum']); //localStorage.setItem('corp', '1122');
         }
         
         
         
    },
    
    
    
      
    update_ticket : function ()   //(event) 
    {        
        this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        
        
        if ( this.rldata['oferta'] && !this.rldata['flags_logic']['is_act_disabled'])
            {
                this.rldata['flags_logic']['is_act_disabled']=true; //заблокируем кнопку
                
                
                 var url=this.$root.rdata['server_auth'];
                 
                 
             var params = {
                  method:  'common__f_update_ticket', 
                   data:  {
                     ticknum : this.rldata['r_tickets']['ticknum'],
                     objcode : this.rldata['r_tickets']['objcode'],
                     qrinfo : this.rldata['r_qrcodes']['qrinfo'],
                     client_tel : this.rldata['client_tel'].replace(/\D/g,''),
                     client_name : this.rldata['client_name'],
                     client_location : this.rldata['client_location'],
                     client_problem : this.rldata['client_problem'],
                     client_raiting : this.rldata['client_raiting'],
                     client_is_requare_call : this.rldata['client_is_requare_call'],
                     is_pressed_send_button : true,
                     img_data : this.rldata['img_data'], 
                  } 
              }; 
        //img_link
      //is_correct_location
      //img_link
      //is_sended_sms_ticket_num
       
    
      
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                               
                              //console.log(response['data']['data']['qrinfo']);
                              
                             // ??? this.rldata['last_insert_qrinfo']=response['data']['data']['qrinfo'];
                              
                            this.rldata['is_camera_form']=false;
                            this.rldata['is_ticket_form']=false;
                            this.rldata['is_fin_form']=true;
                            this.rldata['is_corparea_form']=false;
                            
                            try {  // stop the current video stream
                                if (this.rldata['mediaStream'] != null && this.rldata['mediaStream'].active) {
                                  var tracks = this.rldata['mediaStream'].getVideoTracks();
                                  tracks.forEach(track => {  track.stop(); })      
                                } 
                              } catch (err)  {     console.error(err.message);   }
                            
                        
                 
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                        
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false; //разаблокируем кнопку
                   
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false; //разаблокируем кнопку
                    
                    
            }).then(function () {  });
       
                
                
            }
        
        
    },
   
  
  
  
  
  
  
  





getMediaStream : async function (constraints)   
 {
     
this.rldata['is_show_takephoto_button']=false;
this.rldata['is_requested_device_not_found']=false;
this.rldata['is_premission_denied']=false; 

  try {
      
     
       
      
    this.rldata['mediaStream'] =  await navigator.mediaDevices.getUserMedia(constraints);
    
     
    
    
    
    let video = document.getElementById('cam');    
    video.srcObject = this.rldata['mediaStream'];
    video.onloadedmetadata = (event) => {
      video.play();
      
      this.rldata['is_show_takephoto_button']=true;
      
    };
    
    
    
  } catch (err)  {    
    console.error(err.message);  
    this.rldata['is_faild_get_cam']=true;
    this.rldata['is_requested_device_not_found']=true;
    this.rldata['is_premission_denied']=false;
    
    //alert(err.message);
    
    if (err.message==="Permission denied")
    { 
     this.rldata['is_premission_denied']=true;
     this.rldata['is_requested_device_not_found']=false;
    }
    
   
    
    
  }
  
  
},

switchCamera : async function (cameraMode) {  
  try {
    this.rldata['is_faild_get_cam']=false;  
      
    // stop the current video stream
    if (this.rldata['mediaStream'] != null && this.rldata['mediaStream'].active) {
      var tracks = this.rldata['mediaStream'].getVideoTracks();
      tracks.forEach(track => {
        track.stop();
      })      
    }
    
    // set the video source to null
    document.getElementById('cam').srcObject = null;
    
    // change "facingMode"
    this.rldata['constraints'].video.facingMode = cameraMode;
    
    // get new media stream
    await this.getMediaStream(this.rldata['constraints']);
  } catch (err)  {    
    console.error(err.message); 
    alert(err.message);
  }
},

takePicture : function () {  
  let canvas = document.getElementById('canvas');
  let video = document.getElementById('cam');
  let photo = document.getElementById('photo');  
  let context = canvas.getContext('2d');
  
  const height = video.videoHeight;
  const width = video.videoWidth;
  
  if (width && height) {    
    canvas.width = width;
    canvas.height = height;
    context.drawImage(video, 0, 0, width, height);    
    var data = canvas.toDataURL('image/png');
    photo.setAttribute('src', data);
    
    this.rldata['img_data']=data;
    
  } else {
    this.clearphoto();
  }
},

clearPhoto : function() {
  let canvas = document.getElementById('canvas');
  let photo = document.getElementById('photo');
  let context = canvas.getContext('2d');
  
  context.fillStyle = "#AAA";
  context.fillRect(0, 0, canvas.width, canvas.height);
  var data = canvas.toDataURL('image/png');
  photo.setAttribute('src', data);
},


  
 
switchFrontBtn_click : function() {
   
   this.rldata['camera_type']="user";
        this.switchCamera(this.rldata['camera_type']);
        
},
 
switchBackBtn_click : function() {
   this.rldata['camera_type']="environment";
      this.switchCamera(this.rldata['camera_type']);
        
      
},

snapBtn_click : function() {
       
       
 try{
        
      
      this.takePicture();
      this.rldata['is_snap_photo']=false;
      this.rldata['is_result_photo']=true;
      
    } 
    catch (err)  {    
    console.error(err.message); 
    alert(err.message);
  }
      
      
       //this.event.preventDefault();
},                              
 
remakephoto_click : function() {
       
        try{
      
       this.clearPhoto();
       this.rldata['is_result_photo']=false;
       this.rldata['is_snap_photo']=true;
   
        } 
    catch (err)  {    
    console.error(err.message); 
    alert(err.message);
  }
}, 
 
 ticket_form : function ()   //(event) 
    {    
         this.rldata['is_snap_photo']=true;
         this.rldata['is_result_photo']=false;
         this.rldata['is_camera_form']=false;
         this.rldata['is_ticket_form']=true;
         
         
         this.rldata['is_fin_form']=false;
         this.rldata['is_corparea_form']=false;
    },
     
  
  
  
  
  
  
  
  
  
  
  
  
  
  
},
  
  
 
   computed: { 
        
  
},



/*
  watch: {   
      
       "rldata.is_camera_form": {
                handler(value) {

                   
                        if (this.rldata['is_camera_form'])
                            {
                         
                                    
                          
                           }
                        
                    }
                }
            },*/
  
}
  

</script>


<style scoped>
    .accordion .card .card-header a{
        padding-right: 1.5rem;
    }
    
    .btn_custom_width {
        width: 120px !important;
    }
   
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}  
    
  
</style>